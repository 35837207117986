import React, { useEffect, useState } from 'react';
import Chat from './components/Chat';
import LoginForm from "./components/Login";
import { config } from "./config";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        Boolean(localStorage.getItem("token"))
    );
    const [checkLogin, setCheckLogin] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Function to validate token via API
    const validateToken = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            return false;
        }

        const response = await fetch(config.API_URL + "/api/validate-token", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();
        if (data.valid) {
            return true;
        } else {
            localStorage.removeItem("token");
            return false;
        }
    };
    const handleLogin = async (credentials: { password: string }) => {
        const response = await fetch(config.API_URL + "/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });

        const data = await response.json();
        if (data.success) {
            localStorage.setItem("token", data.token);
            setIsLoggedIn(true);
            setErrorMessage(null);
        } else {
            setErrorMessage("Invalid password. Please try again.");
        }
    };

    useEffect(() => {
        const checkLogin = async () => {
            const isValid = await validateToken();
            setCheckLogin(true);
            setIsLoggedIn(isValid);
        };

        checkLogin();
    }, []);

    return (
        <div className="App">
            {checkLogin && <>{isLoggedIn ? <Chat /> : <LoginForm onLogin={handleLogin} errorMessage={errorMessage} />}</>}
        </div>
    );
}

export default App;
