// LoginForm.tsx
import React, { useState, FormEvent } from "react";
import "../assets/css/login.css";

interface LoginFormProps {
  onLogin: (credentials: { password: string }) => void;
  errorMessage: string | null;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, errorMessage }) => {
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onLogin({ password });
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className="login-title">Login</h2>
       
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            autoFocus
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
