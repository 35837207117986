    import React, { useCallback, useEffect, useRef, useState } from 'react';
    import '../assets/css/chat.css';
    import '../assets/css/style.css';
    import axios from 'axios';
    import { config } from "../config";
    import { ToastContainer } from 'react-toastify';
    import 'react-toastify/dist/ReactToastify.css';
    import NoSleep from 'nosleep.js';
    import endimage from '../assets/images/endimage.png';

    import videoPlaceholder from "../assets/images/circle-gradient.png";
    import { Intro } from './Intro';
    import { v4 as uuidv4 } from 'uuid';
    //Realtime api
    import { RealtimeClient } from '@openai/realtime-api-beta';
    import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
    import { instructions } from '../utils/conversation_config.js';

    /**
     * Type for all event logs
     */
    interface RealtimeEvent {
        time: string;
        source: 'client' | 'server';
        count?: number;
        event: { [key: string]: any };
    }


    interface Message {
        role: 'user' | 'assistant';
        content: string;

    }

    const Chat = () => {

        //Realtime api start

        const startTimeRef = useRef<string>(new Date().toISOString());

        const currentDomain = window.location.hostname;
        const LOCAL_RELAY_SERVER_URL = currentDomain.includes("localhost") ? 'ws://localhost:3001' : 'wss://' + currentDomain;

        const [items, setItems] = useState<any[]>([]);
        const [realtimeEvents, setRealtimeEvents] = useState<any[]>([]);
        const [isConnected, setIsConnected] = useState(false);
        const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});

        //Realtime api end
        const [messages, setMessages] = useState<Message[]>([{ role: 'assistant', content: 'Hello!' }]);
        const noSleep = useRef<NoSleep | null>(null);
        const canvasRef = useRef<HTMLCanvasElement | null>(null);
        const audioContextRef = useRef<AudioContext | null>(null);
        const analyserRef = useRef<AnalyserNode | null>(null);
        const mediaStreamRef = useRef<MediaStream | null>(null);
        const micVisualizationRef = useRef<number | null>(null);
        const [loading, setLoading] = useState<boolean>(false);
        const [noSleepEnabled, setNoSleepEnabled] = useState<boolean>(false);
        const [started, setStarted] = useState<boolean>(false);
        const [permissionStatus, setPermissionStatus] = useState<PermissionState | null>(null);
        const [landingPage, setLandingPage] = useState<boolean>(true); //true
        const [audioMode, setAudioMode] = useState<boolean>(false);

        const [playing, setPlaying] = useState<boolean>(false);
        const messagesEndRef = useRef<HTMLDivElement>(null);
        const audioModeRef = React.useRef<boolean>(false);
        const [chatEnded, setchatEnded] = useState<boolean>(false); //false
        const [shareYes, setShareYes] = useState<boolean>(false);
        const [showsShareInterview, setShowsShareInterview] = useState<boolean>(false);
        const [cardHtml, setCardHtml] = useState<string>('');
        const [endpage, setEndPage] = useState<number>(1);
        const playingRef = React.useRef<boolean>(playing);
        const chatEndedRef = React.useRef<boolean>(chatEnded);

        const isAISpeakingRef = useRef<boolean>(true);
    
        const drawSpeakerWaveform = () => {
            if (!canvasRef.current || !analyserRef.current) return;
        
            const canvas = canvasRef.current;
            const canvasCtx = canvas.getContext('2d');
            const analyser = analyserRef.current;
        
            if (!canvasCtx) return;
        
            analyser.fftSize = 256;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
        
            const draw = () => {
                analyser.getByteFrequencyData(dataArray);
        
                canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        
                const groupWidth = 20;
                const barWidth = 3;
                const gap = 2;
                const groupGap = 4;
                const numBarsPerGroup = 4;
        
                let x = 0;
                for (let i = 0; i < bufferLength; i += numBarsPerGroup) {
                    for (let j = 0; j < numBarsPerGroup; j++) {
                        const dataIndex = i + j;
                        if (dataIndex < bufferLength) {
                            const barHeight = (dataArray[dataIndex] / 255) * (canvas.height / 2) * 0.8;
        
                            canvasCtx.fillStyle = '#7ad9d3';
                            const barX = x + j * (barWidth + gap);
                            canvasCtx.fillRect(barX, canvas.height / 2 - barHeight / 2, barWidth, barHeight);
                            canvasCtx.fillRect(barX, canvas.height / 2 + barHeight / 2, barWidth, -barHeight);
                        }
                    }
                    x += groupWidth + groupGap;
                }
        
                micVisualizationRef.current = requestAnimationFrame(draw);
            };
        
            draw();
        };
        
    
        const startMicrophone = async () => {
            if (canvasRef.current) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    mediaStreamRef.current = stream;

                    audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
                    const source = audioContextRef.current.createMediaStreamSource(stream);

                    analyserRef.current = audioContextRef.current.createAnalyser();
                    analyserRef.current.fftSize = 2048;

                    source.connect(analyserRef.current);

                    drawMicrophoneWaveform();
                    
                } catch (err) {
                    console.error('Error accessing the microphone', err);
                }
            }
        };

        const drawMicrophoneWaveform = () => {
            if (!canvasRef.current || !analyserRef.current) return;

            const canvas = canvasRef.current;
            const canvasCtx = canvas.getContext('2d');
            const analyser = analyserRef.current;

            if (!canvasCtx) return;

            analyser.fftSize = 256;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            const draw = () => {
                    analyser.getByteFrequencyData(dataArray);

                    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

                    const groupWidth = 20;
                    const barWidth = 3;
                    const gap = 2;
                    const groupGap = 4;
                    const numBarsPerGroup = 4;

                    let x = 0;
                    for (let i = 0; i < bufferLength; i += numBarsPerGroup) {
                        for (let j = 0; j < numBarsPerGroup; j++) {
                            const dataIndex = i + j;
                            if (dataIndex < bufferLength) {
                                const barHeight = (dataArray[dataIndex] / 255) * (canvas.height / 2) * 0.8;

                                canvasCtx.fillStyle = '#7ad9d3';
                                const barX = x + j * (barWidth + gap);
                                canvasCtx.fillRect(barX, canvas.height / 2 - barHeight / 2, barWidth, barHeight);
                                canvasCtx.fillRect(barX, canvas.height / 2 + barHeight / 2, barWidth, -barHeight);
                            }
                        }
                        x += groupWidth + groupGap;
                    }

                micVisualizationRef.current = requestAnimationFrame(draw);
            };

            draw();
        };

        const checkMicrophonePermission = async () => {
            try {
                const permission = await navigator.permissions.query({ name: 'microphone' as PermissionName });
                setPermissionStatus(permission.state);
            } catch (err) {
                console.error('Error checking microphone permission', err);
                return null;
            }
        };

        useEffect(() => {
            checkMicrophonePermission()
            noSleep.current = new NoSleep();
        }, []);

        useEffect(() => {
            audioModeRef.current = audioMode;
        }, [audioMode]);

        useEffect(() => {
            chatEndedRef.current = chatEnded;
        }, [chatEnded]);

        useEffect(() => {
            playingRef.current = playing;
        }, [playing]);

        useEffect(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, [messages]);

        const requestMicrophoneAccess = async () => {
            try {
                moveToPage('audio')
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                console.log('Microphone access granted');
                // You can use the stream here, for example, to record audio
            } catch (err) {
                console.error('Microphone access denied', err);
            }
        };

        const enableNoSleep = () => {
            if (noSleep.current && !noSleepEnabled) {
                noSleep.current.enable();
                setNoSleepEnabled(true);
                console.log('NoSleep enabled');
            }
        }
        const handleVoiceInput = async () => {
            setAudioMode(!audioMode);
        };

        const moveToPage = async (mode: string) => {
            enableNoSleep();
            if (mode === 'audio') {
                handleVoiceInput();
            }
            setLandingPage(false);
        };
        const saveAllMessageToDrive = async () => {
            const response = await axios.post(
                config.API_URL + '/api/save-all-to-drive',
                {thread: uuidv4(), messages},
            );
        }
        const handleShare = () => {
            saveAllMessageToDrive();
            setShareYes(true);
            setEndPage(2);
            setTimeout(() => {
                setShareYes(false);
            }, 3000)

        }
        const checkShareInterview = (inputText: string) => {
            // Convert the string to lowercase
            const lowercasedText = inputText.toLowerCase();

            // Check if "share this interview" exists in the lowercase string
            if (lowercasedText.includes("share this interview") || lowercasedText.includes("share interview")) {
                setShowsShareInterview(true);
            }
        }
        const checkEnChat = (inputText: string) => {
            // Convert the string to lowercase
            const lowercasedText = inputText.toLowerCase();

            // Check if "share this interview" exists in the lowercase string
            if (lowercasedText.includes("thank you for your time") || (lowercasedText.includes("share interview") && lowercasedText.includes("button"))) {
                setchatEnded(true);
            }
        }
        const getInsideHtmlText = (inputText: string) => {
            const htmlTagPattern = /\[html\]([\s\S]*?)\[\/html\]/g;
            const matches = [];
            let match;

            while ((match = htmlTagPattern.exec(inputText)) !== null) {
                matches.push(match[1].trim());
            }

            if (matches.length > 0) {
                setchatEnded(true);
                setCardHtml(matches.join("<b/>"));
            }
        };
        const removeHtmlTags = (inputText: string) => {
            const htmlTagPattern = /\[html\][\s\S]*?\[\/html\]/g;
            getInsideHtmlText(inputText);
            if (config.SAVE_LAST) {
                checkShareInterview(inputText);
            }
            checkEnChat(inputText);
            return inputText.replace(htmlTagPattern, '');
        };

        const startChat = () => {
            if (!started) {
                setStarted(true);
                connectConversation();
            }
        }

        //Start realtime api
        /**
     * Instantiate:
     * - WavRecorder (speech input)
     * - WavStreamPlayer (speech output)
     * - RealtimeClient (API client)
     */
        const wavRecorderRef = useRef<WavRecorder>(
            new WavRecorder({ sampleRate: 24000 })
        );
        const wavStreamPlayerRef = useRef<WavStreamPlayer>(
            new WavStreamPlayer({ sampleRate: 24000 })
        );
        const clientRef = useRef<any>(
            new RealtimeClient(
                { url: LOCAL_RELAY_SERVER_URL }
            )
        );

        /**
         * Connect to conversation:
         * WavRecorder taks speech input, WavStreamPlayer output, client is API client
         */
        const connectConversation = useCallback(async () => {
            const client = clientRef.current;
            const wavRecorder = wavRecorderRef.current;
            const wavStreamPlayer = wavStreamPlayerRef.current;

            // Set state variables
            startTimeRef.current = new Date().toISOString();
            setIsConnected(true);
            setRealtimeEvents([]);
            setItems(client.conversation.getItems());

            // Connect to microphone
            await wavRecorder.begin();

            // Connect to audio output
            await wavStreamPlayer.connect();

            // Connect to realtime API
            await client.connect();
            client.sendUserMessageContent([
                {
                    type: `input_text`,
                    text: `Hello!`,
                },
            ]);
            await wavRecorder.record((data) => client.appendInputAudio(data.mono));
            startMicrophone();
            
        }, []);
        /**
         * Core RealtimeClient and audio capture setup
         * Set all of our instructions, tools, events and more
         */
        useEffect(() => {
            // Get refs
            const wavStreamPlayer = wavStreamPlayerRef.current;
            const client = clientRef.current;
            client.updateSession({
                turn_detection: { type: 'server_vad' },
            });
            // Set instructions
            client.updateSession({ instructions: instructions });
            // Set transcription, otherwise we don't get user transcriptions back
            client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

            // Add tools
            client.addTool(
                {
                    name: 'set_memory',
                    description: 'Saves important data about the user into memory.',
                    parameters: {
                        type: 'object',
                        properties: {
                            key: {
                                type: 'string',
                                description:
                                    'The key of the memory value. Always use lowercase and underscores, no other characters.',
                            },
                            value: {
                                type: 'string',
                                description: 'Value can be anything represented as a string',
                            },
                        },
                        required: ['key', 'value'],
                    },
                },
                async ({ key, value }: { [key: string]: any }) => {
                    setMemoryKv((memoryKv) => {
                        const newKv = { ...memoryKv };
                        newKv[key] = value;
                        return newKv;
                    });
                    return { ok: true };
                }
            );


            // handle realtime events from client + server for event logging
            client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
                setRealtimeEvents((realtimeEvents) => {
                    const lastEvent = realtimeEvents[realtimeEvents.length - 1];
                    if (lastEvent?.event.type === realtimeEvent.event.type) {
                        // if we receive multiple events in a row, aggregate them for display purposes
                        lastEvent.count = (lastEvent.count || 0) + 1;
                        return realtimeEvents.slice(0, -1).concat(lastEvent);
                    } else {
                        return realtimeEvents.concat(realtimeEvent);
                    }
                });
            });
            client.on('error', (event: any) => console.error(event));
            client.on('conversation.interrupted', async () => {
                const trackSampleOffset = await wavStreamPlayer.interrupt();
                if (trackSampleOffset?.trackId) {
                    const { trackId, offset } = trackSampleOffset;
                    await client.cancelResponse(trackId, offset);
                }
            });
            client.on('conversation.updated', async ({ item, delta }: any) => {
                
                const items = client.conversation.getItems();
                if (delta?.audio) {
                    wavStreamPlayer.add16BitPCM(delta.audio, item.id);
                }
                if (item.status === 'completed' && item.formatted.audio?.length) {
                    const wavFile = await WavRecorder.decode(
                        item.formatted.audio,
                        24000,
                        24000
                    );
                    item.formatted.file = wavFile;

                    removeHtmlTags(item.formatted.transcript);
                }
                setItems(items);
            });

            setItems(client.conversation.getItems());

            return () => {
                // cleanup; resets to defaults
                client.reset();
            };
        }, []);

        useEffect(() => {
            const itemsData = items.map((x:any) => {
                return {role: x.role, content: x.formatted?.transcript}
            });
            setMessages(itemsData);
        }, [items])

        return (
            <>
                {loading === true && <div className="dot-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
                {(landingPage && loading === false) &&
                    <Intro requestMicrophoneAccess={requestMicrophoneAccess} permissionStatus={permissionStatus}
                        moveToPage={moveToPage} />
                }
                {(chatEnded && landingPage === false) && <div className="landing-container">
                    <div className="homepage-wrapper">
                        <div className="homepage-container">
                            <div className={"homepage-inner-container page" + endpage}>
                                <div className="homepage-welcome homepage-microphone-permission page-item end-page">
                                    <div className="homepage-img">
                                        <img src={endimage} alt="" />
                                    </div>
                                    {cardHtml &&
                                        <div className='card-data' dangerouslySetInnerHTML={{ __html: cardHtml }}></div>}
                                    {!cardHtml &&
                                        <div className="homepage-title">Thank you for connecting with the Art of Meaning</div>}
                                    <div className="homepage-desc">
                                        Please consider sharing this conversation to help my research
                                    </div>

                                    <div className="homepage-btn-wrapper">
                                        <button className="cancel-btn" onClick={() => handleShare()}>Share anonymously
                                        </button>
                                        <button className="cancel-btn" onClick={() => setEndPage(2)}>Do Not Share</button>
                                    </div>
                                </div>
                                <div className="homepage-welcome homepage-microphone-permission page-item">
                                    <div className="homepage-img">
                                        <img src={endimage} alt="" />
                                    </div>
                                    {cardHtml &&
                                        <div className='card-data' dangerouslySetInnerHTML={{ __html: cardHtml }}></div>}
                                    {!cardHtml &&
                                        <div className="homepage-title">Thank you for connecting with the Art of Meaning</div>}
                                    {/* <div className="homepage-title">{shareYes ? "Thanks for sharing!" : "Good luck!"}</div> */}
                                    <div className="homepage-desc">
                                        {cardHtml && "Thank you!"}
                                        {/* Question? Comments?
                                        <br />
                                        <a href='mailto:hello@doctoryaguri.com'>hello@doctoryaguri.com</a> */}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {chatEnded === false && landingPage === false && <div className="chat-container">

                    <div className="taptobegin-wrapper" onClick={() => started === false && startChat()}>
                        <div className="taptobegin-container">
                            {/* {chatEnded &&
                                <div className='end-container'>
                                    <div className='end-icon'><img src={thumbsUpIcon}/></div>
                                    <div className='end-text'>THANK YOU</div>
                                    {cardHtml && <div className='card-data' dangerouslySetInnerHTML={{__html: cardHtml}}></div>}
                                </div>
                            } */}
                            {/* {chatEnded && <div className="end-container">
                                    <button onClick={handleShare} className="copy-button"><img src={tickIcon} width={20} />Share This Interview</button>
                            </div>} */}
                            <div className="middle-gradient gradient-animation">
                                <video
                                    playsInline
                                    autoPlay
                                    muted
                                    loop
                                    className="background-video"
                                    poster={videoPlaceholder}
                                >
                                    <source src="/AI_Motion.mp4" type="video/mp4" />
                                    {/* Fallback image for older browsers */}
                                    <img src={videoPlaceholder} alt="Fallback Image" className="fallback-image" />
                                </video>
                            </div>


                            {/*{started ? (<div
                                className="bottom-speaking-text-area">{listening ? transcript === '' ? 'Listening...' : 'Listening...' : ''}</div>)
                                :
                                <></>
                            }*/}
                            {!started &&
                                <div
                                    className="bottom-speaking-text-area">Tap anywhere to begin</div>
                            }
                            <div className="audio-wave-wrapper">
                                <canvas ref={canvasRef} width="320" height="80" />
                            </div>
                        </div>
                    </div>


                </div>
                }
                <ToastContainer />
            </>
        );
    };

    export default Chat;