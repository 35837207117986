import React, { useState } from "react";
import home1 from "../assets/images/screen1.png";
import home2 from "../assets/images/screen2.png";
import home3 from "../assets/images/screen3.png";

interface IProps {
    requestMicrophoneAccess: () => void;
    permissionStatus: PermissionState | null;
    moveToPage: (page: string) => void;
}

export const Intro = (props: IProps) => {
    const {requestMicrophoneAccess, permissionStatus, moveToPage} = props;
    const [guide, setGuide] = useState<number>(1);

    async function handleContinue() {
        if (guide === 3) {
            if (permissionStatus === "granted") {
                moveToPage("audio");
            } else {
                setGuide(guide + 1);
            }
        } else {
            setGuide(guide + 1);
        }
    }

    return (
        <div className="landing-container">
            <div className="homepage-wrapper">
                <div className="homepage-container">
                    <div className={"homepage-inner-container page" + guide}>
                        <div className="homepage-welcome page-item screen-one">
                            <div className="homepage-welcome-content-container">
                                <div className="homepage-img">
                                    <img src={home1} alt=""/>
                                </div>
                                <div className="homepage-title">
                                    Welcome to The Art of Meaning by Dr. Yaguri
                                </div>
                                <div className="homepage-desc">
                                    Find the meaning of your life and focus on what's most
                                    important to you right now.
                                </div>
                                <div className="homepage-bullets">
                                    <span className="active"></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                    {/* {permissionStatus !== 'granted' && <span className=''></span>} */}
                                </div>
                            </div>
                            <div className="homepage-btn-wrapper">
                                <button className="home-btn" onClick={handleContinue}>
                                    Get Started
                                </button>
                            </div>
                        </div>

                        <div className="homepage-welcome homepage-earphone page-item screen-two">
                            <div className="homepage-welcome-content-container">
                                <div className="homepage-img">
                                    <img src={home2} alt=""/>
                                </div>
                                <div className="homepage-title"></div>
                                <div className="homepage-desc">
                                    Pick a quiet place and set aside 30 minutes for this
                                    conversation.
                                </div>
                                <div className="homepage-bullets">
                                    <span></span>
                                    <span className="active"></span>
                                    <span></span>
                                    {/* {permissionStatus !== 'granted' && <span></span>} */}
                                </div>
                            </div>
                            <div className="homepage-btn-wrapper">
                                <button className="home-btn" onClick={handleContinue}>
                                    Continue
                                </button>
                            </div>
                        </div>

                        {/* <div className="homepage-welcome homepage-quiet-place page-item">
                        <div className="homepage-img">
                            <img src={home3} alt=""/>
                        </div>
                        <div className="homepage-title"></div>
                        <div className="homepage-desc">Find a quiet place or take a walk to reduce
                            distractions, so we can have a meaningful conversation.
                        </div>
                        <div className="homepage-bullets">
                            <span></span>
                            <span></span>
                            <span className='active'></span>
                            {permissionStatus !== 'granted' && <span></span>}
                        </div>
                        <div className="homepage-btn-wrapper">
                            <button className="home-btn" onClick={handleContinue}>Continue</button>
                        </div>
                    </div> */}

                        <div className="homepage-welcome homepage-microphone-permission page-item screen-three">
                            <div className="homepage-welcome-content-container">
                                <div className="homepage-img">
                                    <img src={home3} alt=""/>
                                </div>
                                <div className="homepage-title"></div>
                                <div className="homepage-desc">
                                    Consider using earphones. Grant microphone permission when
                                    prompted.
                                </div>
                                <div className="homepage-bullets">
                                    <span></span>
                                    <span></span>
                                    <span className="active"></span>
                                </div>
                            </div>
                            <div className="homepage-btn-wrapper">
                                <button
                                    className="home-btn"
                                    onClick={() => requestMicrophoneAccess()}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
